// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-dagur-dagur-js": () => import("../src/templates/Dagur/dagur.js" /* webpackChunkName: "component---src-templates-dagur-dagur-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sendu-inn-efni-js": () => import("../src/pages/sendu-inn-efni.js" /* webpackChunkName: "component---src-pages-sendu-inn-efni-js" */),
  "component---src-pages-success-js": () => import("../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-um-okkur-js": () => import("../src/pages/um-okkur.js" /* webpackChunkName: "component---src-pages-um-okkur-js" */)
}

